<template>
  <div>
    <w-top-bar>Regalos</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="grid grid-cols-1 justify-items-stretch mx-1">
        <div class="flex gap-2 justify-between">
          <w-select
            label="Mostrar"
            emptyLabel="Filtros"
            class="z-20 md:w-48"
            v-model="tipo"
            :options="[
              { key: 'all', label: 'Todos' },
              { key: 'sin_canjear', label: 'Por canjear' },
              { key: 'canjeados', label: 'Canjeados' },
            ]"
          />
          <router-link
            :to="{ name: 'discounts-regalos-id', params: { id: 'new' } }"
            class="text-sm text-blue-500 cursor-pointer hover:text-blue-600"
            >Nuevo Regalo</router-link
          >
        </div>
      </div>
      <div>
        <div class="relative pt-2 mx-auto mb-2 text-gray-600">
          <input
            class="w-full h-10 px-5 pr-16 text-sm bg-white border-2 border-gray-300 rounded-lg focus:outline-none"
            type="search"
            v-model="searchTerm"
            name="search"
            placeholder="Buscar por correo electrónico, nombre o código de cupón"
          />
          <button
            @click="doSearch()"
            type="submit"
            class="absolute top-0 right-0 mt-5 mr-4"
          >
            <svg
              class="w-4 h-4 text-gray-600 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
              />
            </svg>
          </button>
        </div>
      </div>
      <transition
        leave-active-class="transition-all duration-300 ease-in-out"
        enter-active-class="transition-all duration-200 ease-in-out"
        enter-class="opacity-0 "
        enter-to-class="opacity-100 "
        leave-to-class="opacity-100 "
        leave-class="opacity-0 "
      >
        <w-regalos
          v-if="!isFindGiftCardPending"
          @remove="toRemove($event)"
          :items="giftCard"
        />
        <w-loading v-else />
      </transition>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex"; // Step 1
import { mapActions } from "vuex";
export default {
  name: "couponsList",
  data() {
    return {
      localcoupons: true,
      tipo: "sin_canjear",
      searchTerm: "",
      timeout: null,
    };
  },
  mixins: [makeFindMixin({ service: "gift-card" })], // Step 2
  computed: {
    giftCardParams() {
      let query = {
        deleted: { $ne: true },
      };

      console.log("this.tipo", this.tipo);

      if (this.tipo === "sin_canjear") query.canjeado = null;
      if (this.tipo === "canjeados") query.idOrden = { $ne: null };

      if (this.searchTerm) {
        query = {};
        query.$or = [
          { email: { $regex: this.searchTerm } },
          { codigo: { $regex: this.searchTerm } },
          { "beneficiario.email": { $regex: this.searchTerm } },
          { "beneficiario.nombre": { $regex: this.searchTerm } },
          { "productos.sku": { $regex: this.searchTerm } },
        ];
      }

      return {
        query: {
          ...query,
          $sort: {
            createdAt: -1,
          },
        },
      };
    },
  },
  watch: {
    localCoupons() {
      this.findGiftCard();
    },
    tipo() {
      this.findGiftCard();
    },
    searchTerm() {
      this.doSearchDebounce();
    },
  },
  methods: {
    ...mapActions("coupons", { create: "create", remove: "remove" }),
    doSearch() {
      console.log("doSearch", this.searchTerm);
      this.findGiftCard();
    },
    doSearchDebounce() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.doSearch();
      }, 300);
    },
    async toRemove(id) {
      console.log(id);
      await this.remove(id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
