<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div
        class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8"
      >
        <div
          class="flex flex-col items-center justify-center h-48"
          v-if="items.length === 0"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">No hay regalos</div>
        </div>
        <div
          v-else
          class="overflow-hidden shadow bregalo-b bregalo-gray-200 sm:rounded-lg"
        >
          <table
            class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg"
          >
            <thead
              class="hidden md:table-header-group print:table-header-group"
            >
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Nombre
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Tipo
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden bg-gray-50"
                >
                  Monto
                </th>
                <!-- <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:table-cell bg-gray-50"
                >
                  Fecha
                </th> -->
                <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Estado
                </th>
                <th
                  class="py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase print:hidden sm:hidden bg-gray-50"
                >
                  Procesado
                </th>
                <!-- <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:table-cell bg-gray-50"
                ></th> -->
              </tr>
            </thead>
            <tbody
              class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none"
            >
              <tr
                class="flex flex-col mb-2 flex-no wrap sm:table-row print:table-row sm:mb-0"
                v-for="regalo in items"
                :key="regalo._id"
              >
                <td class="px-6 py-3 overflow-hidden whitespace-no-wrap">
                  <div class="text-sm font-medium">{{ regalo.codigo }}</div>
                  <div
                    v-if="regalo.beneficiario && regalo.beneficiario.nombre"
                    class="text-sm font-semibold"
                  >
                    {{ regalo.beneficiario.nombre }}
                    {{ regalo.beneficiario.apellido }}
                  </div>

                  <!-- SKUS y cantidades -->
                  <div v-if="regalo.productos" class="text-sm flex">
                    <div
                      v-for="prod in regalo.productos"
                      :key="prod._id"
                      class="flex items-center mr-2"
                    >
                      <div class="text-xs">
                        {{ prod.cantidad }} x {{ prod.sku }}
                      </div>
                    </div>
                    <div v-if="regalo.idOrden" class="text-xs text-green-500">
                      - Canjeado
                    </div>
                    <div v-if="!regalo.idOrden" class="text-xs text-red-500">
                      - Sin canjear
                    </div>
                  </div>
                </td>
                <td class="px-6 text-sm whitespace-no-wrap md:py-3">
                  <div class="" v-if="regalo.type == 'amount'">Monto Fijo</div>
                  <div class="" v-if="regalo.type == 'percentage'">
                    Porcentaje
                  </div>
                </td>
                <td
                  class="hidden px-6 py-4 text-sm whitespace-no-wrap md:table-cell"
                  style="vertical-align: top"
                >
                  <div>
                    <w-price
                      v-if="regalo.type == 'amount'"
                      :value="Number(regalo.amount.ars)"
                    ></w-price>
                    <w-price
                      v-if="regalo.type == 'amount'"
                      currency="USD"
                      :value="Number(regalo.amount.usd)"
                    ></w-price>
                    <div v-if="regalo.type == 'percentage'">
                      {{ regalo.percentage }}%
                    </div>
                  </div>
                  <div class="text-xs">
                    <div v-if="regalo.method == 'wire'" class="text-gray-500">
                      Bancario
                    </div>
                    <div
                      v-if="regalo.method == 'mercadopago'"
                      class="text-gray-500"
                    >
                      Mercado Pago
                    </div>
                    <div v-if="regalo.method == 'paypal'" class="text-gray-500">
                      Paypal
                    </div>
                    <div v-if="regalo.method == 'stripe'" class="text-gray-500">
                      Stripe
                    </div>
                    <div v-if="regalo.method == 'free'" class="text-gray-500">
                      Gratis
                    </div>
                  </div>
                </td>
                <td
                  style="vertical-align: top"
                  class="px-6 py-4 text-sm font-medium leading-5 whitespace-no-wrap flex justify-end items-center"
                >
                  <router-link
                    v-if="regalo.idOrden"
                    class="text-indigo-600 hover:text-indigo-900"
                    :to="{
                      name: 'orders',
                      query: { search: regalo.idOrden },
                    }"
                  >
                    Ver pedido
                  </router-link>
                  <router-link
                    v-if="regalo.idOrden"
                    class="text-green-600 hover:text-green-900 ml-1"
                    target="_blank"
                    :to="{
                      name: 'orders',
                      query: { search: regalo.idOrden },
                    }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-3 h-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                  </router-link>
                  <!-- Else Editar -->
                  <router-link
                    v-if="!regalo.idOrden"
                    class="text-indigo-600 hover:text-indigo-900"
                    :to="{
                      name: 'regalos-id',
                      params: { id: regalo._id },
                    }"
                    >Editar</router-link
                  >
                </td>
              </tr>

              <!-- More rows... -->
            </tbody>
          </table>
          <div
            v-if="totalResults > limit"
            @click="nextPage()"
            class="items-center p-3 text-sm font-medium text-center text-indigo-300 cursor-pointer glex hover:text-indigo-500"
          >
            Cargar 20 más de {{ totalResults - limit }} resultados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showImage: null,
      upload: null,
      limit: 20,
      busy: false,
    };
  },
  methods: {
    remove(id) {
      this.$emit("remove", id);
    },
    async archive(regalo, status) {
      console.log("archive");
      this.busy = regalo._id;
      await this.$store.dispatch("food-regalos/patch", [
        regalo._id,
        { archive: status },
        {},
      ]);
      this.$emit("reload");
      this.busy = false;
    },
    nextPage() {
      this.limit += 20;
      this.$emit("next-page", this.limit);
    },
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  bregalo-bottom: 0;
}

th:not(:last-child) {
  bregalo-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
