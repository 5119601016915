import { render, staticRenderFns } from "./WRegalos.vue?vue&type=template&id=27d06912&scoped=true&"
import script from "./WRegalos.vue?vue&type=script&lang=js&"
export * from "./WRegalos.vue?vue&type=script&lang=js&"
import style0 from "./WRegalos.vue?vue&type=style&index=0&id=27d06912&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d06912",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WPrice from '@/components/WPrice.vue'
autoInstallComponents(component, {WPrice})
